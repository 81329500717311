import {
    Container,
    Box,
    Card,
    CardHeader,
    Button,
    Typography,
    ButtonGroup,
    Grid,
    FormControl, OutlinedInput, Select, MenuItem, Alert
} from "@mui/material";
import {Header} from "../components/Header";
import { QrReader } from "react-qr-reader";
import QrScanner from 'qr-scanner';
import {useEffect, useState} from "react";
import {nbsColors} from "../constants/colors";
import {
    createEmptyNationalId,
    createNationalIdFromIDScannedData
} from "../utils/IDScanUtils";
import NationalIDScannedDetails from "../components/NationalIDScannedDetails";
import CropFreeRoundedIcon from '@mui/icons-material/CropFreeRounded';
import * as React from "react";
import {useNavigate} from "react-router-dom";
import CommonsAPI from "../api/CommonsAPI";
import LoanApplication from "../structs/LoanApplication";
import NextButton from "../components/NextButton";
import {errorMessages} from "../constants/messages";
import loanPurposes from "../constants/loanPurposes";
import ConnectivityChecker from "../components/ConnectivityChecker";
import Loader from "../components/Loader";
import BackGroundPaper from "../components/BackGroundPaper";
import nationalIdImageSample from "../assets/images/national_id_sample.jpg";

const CAMERA_MODE = {
    BACK: "environment",
    FRONT: "user"
}

const actionButtonStyle = {
    ":hover": {
        border: `1px solid ${nbsColors.red}`,
        color: nbsColors.red,
        fontWeight: 600
    },
    borderRadius: "10px",
    border: `1px solid ${nbsColors.lightGrey}`,
    color: nbsColors.blue
}
const scanNoticeText = 'Please begin by scanning the QR code on your national ID. Alternatively, ' +
    'you can upload the QR code from your national ID using the buttons below.';
const branchCodeEmptyErrorMsg = 'Please select service center'
const accountNumberEmptyErrorMsg = 'Please enter account number'
const loanPurposeEmptyErrorMsg = 'Please select loan purpose'

export const AccountInformationPage = () => {
    const [selectedCameraMode, setSelectedCameraMode] = useState(CAMERA_MODE.BACK);
    const [nationalId, setNationalId] = useState(createEmptyNationalId());
    const [scanError, setScanError] = useState(false);
    const [scanning, setScanning] = useState(false);
    const [branches, setBranches] = useState([]);
    const [branchCode, setBranchCode] = useState("");
    const [loanPurpose, setLoanPurpose] = useState("");

    const qrCodeFileInputRef = React.useRef(null);

    const [accountNumber, setAccountNumber] = useState('');
    const [validationResponse, setValidationResponse] = useState(null);
    const [validatingAccount, setValidatingAccount] = useState(false);
    const [branchCodeIsEmpty, setBranchCodeIsEmpty] = useState(false);
    const [loanPurposeIsEmpty, setLoanPurposeIsEmpty] = useState(false);
    const [accountNumberIsEmpty, setAccountNumberIsEmpty] = useState(false);
    const [readingQrCodeFromFile, setReadingQrCodeFromFile] = useState(false);

    const navigate = useNavigate();

    const handleAccountNumberChange = (event) => {
        const inputValue = event.target.value;
        const sanitizedInput = inputValue.replace(/\D/g, '');
        setAccountNumber(sanitizedInput);
    };

    useEffect(() => {
        return () => {
            setScanning(false)
        }

    }, [])

    useEffect(() => {
        console.log("Fetching branches")
        new CommonsAPI().fetchBranches()
            .then((branches) => {
                setBranches(branches)
            })
    }, [])

    const resetEntries = () => {
        setLoanPurposeIsEmpty(false)
        setBranchCodeIsEmpty(false)
        setAccountNumberIsEmpty(false)
        setScanError(false)
        setNationalId(createEmptyNationalId());
    }

    const onScanErrorHandler = (err) => {
        if (!scanning) {
            setScanError(true)
        }
    }

    const onScanHandler = (scanResult) => {
        const _nationalIDData = createNationalIdFromIDScannedData(scanResult);
        if (_nationalIDData.isOk()) {
            setNationalId(_nationalIDData);
            setScanning(false);
        }
        setScanError(false)
    }

    const scanFunc = (e) => {
        e.preventDefault();
        setScanning(true);
        resetEntries();
    }
    const uploadFunc = (e) => {
        e.preventDefault();
        setScanning(false);
        resetEntries();
        qrCodeFileInputRef.current.click();
    }

    const handleQrCodeFileUpload = (event) => {
        event.preventDefault();
        const image = event.target.files[0];

        setReadingQrCodeFromFile(true)

        if (!image) return; //TODO: Handle well
        QrScanner.scanImage(image, {returnDetailedScanResult: true})
            .then(result => {
                onScanHandler(result.data)
            })
            .catch(error => {
                console.log(error || 'No QR code found.')
                setScanError(true)
            }).finally(() => {
                setReadingQrCodeFromFile(false)
            })
    };

    useEffect(() => {
        if (scanError) {
            setNationalId(createEmptyNationalId())
        }
    }, [scanError]);

    const validateAccount = async () => {
        setValidatingAccount(true)
        await new CommonsAPI().validateAccount(nationalId, accountNumber)
            .then((res) => {
                setValidationResponse(res)
                if (res.status) {
                    goToNextPage(res.msg);
                }
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setValidatingAccount(false)
            })
    }

    const goToNextPage = async (msg) => {
        const loanApplication = new LoanApplication();
        loanApplication.national_Id = nationalId;
        loanApplication.account_number = accountNumber;
        loanApplication.otp_message = msg;
        loanApplication.branch_code = branchCode;
        loanApplication.loan_purpose = loanPurpose;

        navigate("/otp", {state: loanApplication})
    }

    const validationHasError = () => {
        return validationResponse && !validationResponse?.status;
    }

    return (
        <BackGroundPaper>
            <>
                <Header/>
                <ConnectivityChecker/>
                <Container maxWidth="md" sx={{ marginTop: "1rem" }}>
                    <Box>
                        <Box sx={{ borderRadius: "100px", marginTop: "1rem"}}>
                            <Card elevation={0} sx={{ borderRadius: "10px" }}>
                                <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CropFreeRoundedIcon sx={{ color: nbsColors.red }} fontSize="large"/>
                                </Box>

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CardHeader sx={{ color: nbsColors.blue }}  title="Scan National ID QR Code" />
                                </div>

                                <Container maxWidth="sm">
                                    <Typography color={nbsColors.blue}>{scanNoticeText}</Typography>
                                </Container>


                                {!scanning && (<>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            '& > *': {
                                                m: 1,
                                            },
                                        }}
                                    >
                                        <input
                                            ref={qrCodeFileInputRef}
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleQrCodeFileUpload}
                                        />
                                        <ButtonGroup size="large" disableElevation variant="outlined">
                                            <Button sx={actionButtonStyle} onClick={scanFunc}>Scan</Button>
                                            <Button sx={actionButtonStyle} onClick={uploadFunc}>Upload</Button>
                                        </ButtonGroup>

                                        {!nationalId || !nationalId.isOk() ? (
                                            <Box p={2} sx={{ width: '100%', maxWidth: '400px', mt: 1 }}>
                                                <img
                                                    src={nationalIdImageSample}
                                                    alt="Sample national Id"
                                                    style={{ width: '100%', height: 'auto', display: 'block', borderRadius: '8px' }}
                                                />
                                            </Box>
                                        ) : null}
                                    </Box>
                                </>)}

                                <Container maxWidth="sm">
                                    {!scanning && readingQrCodeFromFile && <>
                                        <Loader/>
                                    </>}

                                    {scanning && <QrReader
                                        videoContainerStyle={{
                                            borderColor: "red"
                                        }}
                                        onResult={(result, error) => {
                                            if (!!result) { onScanHandler(result?.text) }
                                            if (!!error) { onScanErrorHandler(error) }
                                        }}
                                        constraints={{
                                            facingMode: selectedCameraMode
                                        }}/>
                                    }

                                    {scanError && (<Box mb={2}>
                                        <Alert severity="error" icon={false}>
                                            <Typography fontWeight={600} color={nbsColors.red}>{errorMessages.couldNotReadQrCode}</Typography>
                                        </Alert>
                                    </Box>)}

                                    {nationalId && nationalId.isOk() &&
                                        <>
                                            <Box mt={2} mb={4} p={1} sx={{ borderRadius: '10px', border: `1px solid ${nbsColors.lightGrey}` }}>
                                                <NationalIDScannedDetails nationalId={nationalId}/>
                                            </Box>

                                            <Box mb={2}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={4}>
                                                        <FormControl size="small" fullWidth>
                                                            <Select
                                                                sx={{ borderRadius: '100px' }}
                                                                labelId="service-center-label"
                                                                defaultValue=""
                                                                displayEmpty
                                                                onChange={(e) => setBranchCode(e.target.value)}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Service Center
                                                                </MenuItem>
                                                                {branches.length > 0 && branches.map((branch, index) => {
                                                                    return <MenuItem key={index} value={branch?.branchId}>
                                                                        {branch?.branchName}
                                                                    </MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FormControl placeholder="Loan purpose" size="small" fullWidth>
                                                            <Select
                                                                sx={{ borderRadius: '100px' }}
                                                                labelId="loan-purpose-label"
                                                                defaultValue=""
                                                                displayEmpty
                                                                onChange={(e) => setLoanPurpose(e.target.value)}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Loan purpose
                                                                </MenuItem>
                                                                {loanPurposes.map((purpose, index) => {
                                                                    return <MenuItem key={index} value={purpose}>
                                                                        {purpose}
                                                                    </MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FormControl size="small" fullWidth>
                                                            <OutlinedInput
                                                                sx={{ borderRadius: '100px' }}
                                                                placeholder="Account number"
                                                                value={accountNumber}
                                                                onChange={handleAccountNumberChange}
                                                                inputProps={{ maxLength: 10 }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {!validatingAccount && validationHasError() && (
                                                <Box mb={2}>
                                                    <Alert severity="error" icon={false}>
                                                        <Typography color={nbsColors.red}>{validationResponse?.msg}</Typography>
                                                    </Alert>
                                                </Box>
                                            )}

                                            {(branchCodeIsEmpty || accountNumberIsEmpty || loanPurposeIsEmpty) && (
                                                <Box mb={2}>
                                                    <Alert severity="error" icon={false}>
                                                        <Typography fontWeight={600} color={nbsColors.red}>{
                                                            branchCodeIsEmpty
                                                                ? branchCodeEmptyErrorMsg
                                                                : accountNumberIsEmpty
                                                                    ? accountNumberEmptyErrorMsg
                                                                    : loanPurposeEmptyErrorMsg
                                                        }</Typography>
                                                    </Alert>
                                                </Box>
                                            )}

                                            <Box mb={2}>
                                                <Grid container display="flex" justifyContent="space-between">
                                                    <Grid item justifyContent="flex-start"></Grid>
                                                    <Grid item justifyContent="flex-end">
                                                        <NextButton
                                                            loaderEffect={validatingAccount}
                                                            onNextCallback={async () => {
                                                                const branchCodeIsSelected = branchCode.length > 0;
                                                                const accountIsIsEntered = accountNumber.length > 0;
                                                                const loanPurposeIsSelected = loanPurpose.length > 0
                                                                    && loanPurposes.includes(loanPurpose);

                                                                setBranchCodeIsEmpty(!branchCodeIsSelected);
                                                                setAccountNumberIsEmpty(!accountIsIsEntered);
                                                                setLoanPurposeIsEmpty(!loanPurposeIsSelected)

                                                                if (branchCodeIsSelected && accountIsIsEntered && loanPurposeIsSelected) {
                                                                    await validateAccount();
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                </Container>
                            </Card>
                        </Box>
                    </Box>
                </Container>
            </>
        </BackGroundPaper>
    );
}
