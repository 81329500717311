import React, {useEffect, useState} from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import logoImage from "../assets/images/logo_nav.png";
import { nbsColors } from "../constants/colors";
import { useNavigate } from "react-router-dom";
import CommonsAPI from "../api/CommonsAPI";

export const Header = React.memo(() => {
    const [appVersion, setAppVersion] = useState(null);
    const navigate = useNavigate();

    const goHome = () => {
        navigate("/");
    }

    useEffect(() => {
        new CommonsAPI().getAppVersion()
            .then(version => setAppVersion(version))
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: nbsColors.red }}>
                <Toolbar>
                    <div style={{ cursor: "pointer" }} onClick={goHome}>
                        <img src={logoImage} alt="NBS Bank Logo" width={30} height={30} />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={goHome}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                pl: "5px"
                            }}
                        >
                            Instant Loans
                        </Typography>
                    </div>

                    <Box sx={{ flexGrow: 1 }} />

                    {appVersion !== null && (<>
                        <div>
                            <Typography fontWeight={600}>
                                {`version: ${appVersion}`}
                            </Typography>
                        </div>
                    </>)}

                </Toolbar>
            </AppBar>
        </Box>
    );
});
